import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Transition, Button } from '@headlessui/react';
import { LoaderIcon } from './Icons';

const getUserConfirmationText = (store) => {
  if (store.unreadyTeamCount > 1) {
    return store.solvedDemoChallenge
      ? store.i18n.rosetta.t('readyBanner.listenedToExplanation')
      : store.i18n.rosetta.t('readyBanner.demoNotSolved');
  }
  return store.teamCount > 1
    ? store.i18n.rosetta.t('readyBanner.lastTeam')
    : store.i18n.rosetta.t('readyBanner.onlyTeam');
};

const ReadyBanner = observer(function ReadyBanner({ store }) {
  async function readyButtonHandler() {
    if (!store.currentTeam.isReady && !window.confirm(getUserConfirmationText(store))) return;
    await store.currentTeam.setIsReady(true);
  }

  return (
    <Transition
      show={store.view.showReadyBanner}
      enter="transition ease-in-out duration-300"
      enterFrom="translate-y-full"
      enterTo="translate-y-0"
      leave="transition ease-in-out duration-300"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-full"
      className="fixed bottom-0 left-0 z-30 w-full"
    >
      <div className="flex h-12 items-center overflow-hidden bg-indigo-600 p-2 shadow-lg">
        <div className="flex flex-1 items-center justify-between gap-2">
          <p className="text-sm text-white">
            {store.currentTeam.isReady ? (
              <div className="mr-2.5 inline-flex items-center gap-x-2 py-1 text-white">
                <LoaderIcon className="size-5 shrink-0" />
                {store.i18n.rosetta.t('readyBanner.waitingFor', {
                  count: store.unreadyTeamCount,
                })}
              </div>
            ) : (
              store.i18n.rosetta.t('readyBanner.clickReady')
            )}
          </p>
          {store.currentTeam.isLoading && (
            <div className="mr-2.5 py-1 text-white">
              <LoaderIcon className="size-5" />
            </div>
          )}
          {!store.currentTeam.isLoading && !store.currentTeam.isReady && (
            <Button
              disabled={store.currentTeam.isLoading}
              onClick={readyButtonHandler}
              className={clsx(
                'flex items-center justify-center whitespace-nowrap rounded-md border border-transparent px-2.5 py-1 text-sm font-medium',
                !store.currentTeam.isLoading && store.currentTeam.isReady
                  ? 'border border-white text-white'
                  : 'bg-white text-indigo-600'
              )}
            >
              <span>✓ {store.i18n.rosetta.t('readyBanner.ready')}</span>
            </Button>
          )}
        </div>
      </div>
    </Transition>
  );
});

export default ReadyBanner;
