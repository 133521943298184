/* eslint-disable react-refresh/only-export-components */
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FireIcon } from '@heroicons/react/20/solid';
import { StarsIcon, MedalIcon } from './Icons';

const getBgColor = (challenge) => {
  if (challenge.rootStore.inReviewMode) {
    return challenge.isCorrect ? 'bg-green-600' : 'bg-red-600';
  }
  return 'bg-black';
};

function Marker({ challenge, alwaysShow = false }) {
  return (
    <div
      className={clsx(
        'cursor-pointer select-none overflow-hidden rounded font-sans font-medium shadow',
        {
          hidden: !(alwaysShow || challenge.showOnMap),
        }
      )}
    >
      <div className="flex">
        <div className={clsx('px-2 py-1', getBgColor(challenge))}>
          <div className="text-center text-base font-bold leading-none text-white">
            {challenge.isDemo ? (
              'DEMO'
            ) : (
              <>
                <span>
                  {challenge.rootStore.inReviewMode ? challenge.points ?? 0 : challenge.totalPoints}
                </span>
                <span className="text-xs leading-none opacity-60">xp</span>
              </>
            )}
          </div>
          <div className="mt-0.5 text-gray-100">
            <StarsIcon className="size-3" count={challenge.difficulty} />
          </div>
        </div>
        {!challenge.rootStore.inReviewMode && challenge.speedBonus > 0 && (
          <div className="flex flex-col items-center justify-center bg-violet-400 px-1 text-violet-900">
            <MedalIcon className="size-4" />
            <span className="text-xs font-black leading-none">{challenge.speedBonus}</span>
          </div>
        )}
        {!challenge.rootStore.inReviewMode && challenge.reward > 0 && (
          <div className="flex flex-col items-center justify-center bg-amber-400 px-1 text-amber-900">
            <FireIcon className="size-4" />
            <span className="text-xs font-black leading-none">{challenge.reward}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(Marker);
