/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export function usePhotoSwipe(options) {
  const [lightbox, setLightbox] = useState(null);

  useEffect(() => {
    let lb = new PhotoSwipeLightbox({
      pswpModule: () => import('photoswipe'),
      loop: false,
      // arrowPrevSVG: leftArrowSVGString,
      // arrowNextSVG: leftArrowSVGString,
      ...options,
    });

    setLightbox(lb);

    lb.on('uiRegister', () => {
      lb.pswp.ui.registerElement({
        name: 'caption',
        order: 9,
        isButton: false,
        onInit: (el) => {
          lb.pswp.on('change', () => {
            const caption = lb.pswp.currSlide?.content?.data?.caption ?? null;
            el.innerHTML = caption ?? '';
            el.className = caption
              ? 'absolute top-0 left-0 m-4 px-2.5 py-1.5 bg-black bg-opacity-60 rounded text-gray-100 text-sm font-semibold'
              : 'hidden';
          });
        },
      });
    });

    lb.init();

    return () => {
      lb.destroy();
      lb = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return lightbox;
}
