import clsx from 'clsx';

const Badge = ({ className, children }) => (
  <span
    className={clsx(
      'inline-flex items-center rounded-full px-3 py-0.5 text-sm font-semibold',
      className
    )}
  >
    {children}
  </span>
);

export default Badge;
