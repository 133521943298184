import { makeAutoObservable, runInAction, values, entries } from 'mobx';
import { truncate } from '../lib/utils';
import { setIsReady } from '../lib/api';

class TeamStore {
  id;

  name = '';

  urlId = '';

  score = 0;

  correctAnswerCount = 0;

  scoreWithoutRewards = 0;

  isLoading = false;

  isReady = false;

  players = [];

  clients = new Map();

  rootStore;

  constructor(rootStore, id) {
    makeAutoObservable(this, {
      id: false,
      rootStore: false,
      name: false,
      urlId: false,
    });
    this.rootStore = rootStore;
    this.id = id;
  }

  // is this the team that belongs to the current url session?
  get isCurrent() {
    return this.rootStore.currentTeamId === this.id;
  }

  get rank() {
    return this.rootStore.teamsByRankAsArray.findIndex((t) => t.id === this.id) + 1;
  }

  get clientsAsArray() {
    return values(this.clients);
  }

  get allClientsAsGeoJson() {
    const features = this.clientsAsArray.map((client) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [client.longitude, client.latitude],
      },
      properties: {
        title: truncate(this.name, 12),
      },
    }));

    return { type: 'FeatureCollection', features };
  }

  setScore(score) {
    this.score = score;
  }

  upsertClient(clientId, coordinates) {
    const location = { ...coordinates, updatedAt: Date.now() };
    this.clients.set(clientId, location);
    // check if there are any stale clients (no recent location update)
    this.deleteStaleClients();
    this.rootStore.map.paintClientMarkersForTeam(this);
    return location;
  }

  deleteStaleClients() {
    entries(this.clients).forEach(([id, client]) => {
      const timeAgoInMs = Date.now() - client.updatedAt;
      // older than 10 secs
      if (timeAgoInMs > 10000) this.clients.delete(id);
    });
  }

  async setIsReady(isReady = true) {
    if (this.isLoading) return;

    this.isLoading = true;
    try {
      const response = await setIsReady(this.id, isReady);
      if (response) this.updateFromJson({ isReady: response.isReady });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  updateFromJson(json) {
    Object.keys(json).forEach((key) => {
      this[key] = json[key];
    });
  }
}

export default TeamStore;
