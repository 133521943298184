import { makeObservable, action, observable } from 'mobx';
import PersistentWebSocket from 'pws';
import { OP_CODES, WS_EVENTS } from '../lib/constants';

class SyncStore {
  // intervalId;
  rootStore;

  // the client id that the server assigns
  clientId;

  // are we connected to the WS server?
  isConnected = false;

  isReady = false;

  ping = -1;

  lastPingTimestamp = -1;

  lastHeartbeatAcked = true;

  constructor(rootStore) {
    makeObservable(this, {
      isConnected: observable,
      isReady: observable,
      ping: observable,
      setIsConnected: action,
      setIsReady: action,
      setClientId: action,
      setPing: action,
    });
    this.rootStore = rootStore;
  }

  connect() {
    if (!this.rootStore.currentTeamId) throw new Error('Requires a valid session id');

    this.ws = new PersistentWebSocket(
      `${import.meta.env.VITE_WS_URL}/${this.rootStore.currentTeamId}`,
      { pingTimeout: 30 * 1000 }
    );

    this.ws.onopen = () => {
      console.log('💡 connected');
      this.setIsConnected(true);
    };

    this.ws.onclose = () => {
      console.log('🌛 disconnected');
      this.setIsConnected(false);
      this.destroy();
    };

    this.ws.onmessage = (event) => {
      const packet = JSON.parse(event.data);
      this.handlePacket(packet);
    };

    // this.ws.onerror = (event) => console.log(event);
  }

  setIsConnected(isConnected) {
    this.isConnected = isConnected;
  }

  setIsReady(isReady) {
    this.isReady = isReady;
  }

  setClientId(id) {
    this.clientId = id;
  }

  setPing(ping) {
    this.ping = ping;
  }

  handlePacket(packet) {
    switch (packet.op) {
      case OP_CODES.HELLO:
        this.setHeartbeatTimer(packet.d.heartbeatInterval);
        this.setClientId(packet.d.id);
        // ask for initial game state from server
        this.send({ op: OP_CODES.IDENTIFY });
        break;
      case OP_CODES.HEARTBEAT_ACK:
        this.ackHeartbeat();
        break;
      case OP_CODES.GAME_ACTION:
        this.handleGameAction(packet);
        break;
      default:
        break;
    }
  }

  handleGameAction(packet) {
    if (packet.t === WS_EVENTS.SYNC) {
      this.rootStore.setTeams(packet.d.teams);
      this.rootStore.setChallenges(packet.d.challenges);
      this.rootStore.setNotifications(packet.d.notifications);
      this.rootStore.updateFromJson({
        state: packet.d.state,
        game: packet.d.game,
        startedAt: packet.d.startedAt,
        countdownStartedAt: packet.d.countdownStartedAt,
        showScoreboard: packet.d.showScoreboard,
      });
      this.setIsReady(true);
      return;
    }
    if (packet.t === WS_EVENTS.MATCH_UPDATE) {
      this.rootStore.updateFromJson(packet.d);
      return;
    }
    if (packet.t === WS_EVENTS.MATCH_START) {
      this.rootStore.updateFromJson(packet.d);
      /*
        Make sure we always show the map once the countdown is finished
        This way our users know new missions appeared on the map
      */
      this.rootStore.view.closePanel();
      return;
    }
    if (packet.t === WS_EVENTS.TEAM_UPDATE) {
      const team = this.rootStore.teams.get(packet.d.id);
      // todo:
      if (team) team.updateFromJson(packet.d);
      // else FLAG THIS WRONG PACKAGE, why is this happening?
      return;
    }
    if (packet.t === WS_EVENTS.CHALLENGE_UPDATE) {
      // if it's an array we want to bulk update challenges
      const challengesFromPacket = Array.isArray(packet.d) ? packet.d : [packet.d];
      for (const challengeInJson of challengesFromPacket) {
        const challenge = this.rootStore.challenges.get(challengeInJson.id);
        if (challenge) challenge.updateFromJson(challengeInJson);
      }
      return;
    }
    if (packet.t === WS_EVENTS.CHALLENGE_REVEAL) {
      this.rootStore.setChallenges(packet.d);
      return;
    }
    if (packet.t === WS_EVENTS.NOTIFICATION_CREATE) {
      const notification = this.rootStore.addNotification(packet.d);
      this.rootStore.view.showNotification(notification);
    }
    if (packet.t === WS_EVENTS.USER_LOCATION_UPDATE) {
      const { clientId, teamId, ...coordinates } = packet.d;
      // ignore our own location updates
      if (clientId === this.clientId) return;
      const team = this.rootStore.teams.get(teamId);
      console.log(`receiving geo from ${clientId}`, coordinates);
      team.upsertClient(clientId, coordinates);
    }
  }

  send(data) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN && this.isConnected) {
      this.ws.send(JSON.stringify(data));
    }
  }

  /**
   * Sets the heartbeat timer.
   * If -1, clears the interval, any other number sets an interval
   */
  setHeartbeatTimer(time) {
    if (time === -1) {
      if (this.heartbeatInterval) {
        console.log('Clearing the heartbeat interval.');
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = undefined;
      }
      return;
    }
    console.log(`❤️ Setting a heartbeat interval for ${time}ms.`);
    // Sanity checks
    if (this.heartbeatInterval) clearInterval(this.heartbeatInterval);
    this.heartbeatInterval = setInterval(() => this.sendHeartbeat(), time);
  }

  /**
   * Sends a heartbeat to the WebSocket.
   * If this shard didn't receive a heartbeat last time, it will destroy it and reconnect
   */
  sendHeartbeat(tag = 'HeartbeatTimer') {
    if (!this.lastHeartbeatAcked) {
      console.log(
        `[${tag}] Didn't receive a heartbeat ack last time, assuming zombie connection. Destroying and reconnecting.`
      );
      this.destroy();
      return;
    }

    console.log(`[${tag}] Sending a heartbeat.`);
    this.lastHeartbeatAcked = false;
    this.lastPingTimestamp = Date.now();
    this.send({ op: OP_CODES.HEARTBEAT });
  }

  /**
   * Acknowledges a heartbeat.
   */
  ackHeartbeat() {
    this.lastHeartbeatAcked = true;
    const latency = Date.now() - this.lastPingTimestamp;
    console.log(`Heartbeat acknowledged, latency of ${latency}ms.`);
    this.setPing(latency);
  }

  destroy() {
    // clear up any intervals
    this.setHeartbeatTimer(-1);
  }
}

export default SyncStore;
