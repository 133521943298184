import { Fragment } from 'react';
import { Transition, Button } from '@headlessui/react';
import { observer } from 'mobx-react-lite';
// import clsx from 'clsx';
import {
  MapPinIcon,
  EyeSlashIcon,
  PlayIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/solid';
import { NOTIFICATION_TYPES } from '../lib/constants';

const { START, GAME_OVER, CHALLENGE_REVEAL, CHALLENGE_SOLVE, SCOREBOARD_HIDE } = NOTIFICATION_TYPES;

const Notification = ({ icon, title, subtitle }) => (
  <div className="relative w-full p-2">
    <div className="absolute left-0 ml-2">
      <div className="flex size-10 items-center justify-center rounded-full bg-gray-900/75">
        {icon}
      </div>
    </div>
    <div className="text-center">
      <p className="text-sm font-medium text-gray-100">{title}</p>
      {subtitle && <p className="text-sm text-gray-400">{subtitle}</p>}
    </div>
  </div>
);

function renderNotification(notification, store) {
  if (notification.type === SCOREBOARD_HIDE)
    return (
      <Notification
        title={store.i18n.rosetta.t('notificationBanner.scoreboardHidden')}
        subtitle={store.i18n.rosetta.t('notificationBanner.remainingMinutes', {
          minutes: 20,
        })}
        icon={<EyeSlashIcon className="size-5 text-gray-400" aria-hidden="true" />}
      />
    );
  if (notification.type === CHALLENGE_REVEAL) {
    const { challengeIds } = notification.metadata;
    const challengeCount = challengeIds.length;
    const challenge = store.challenges.get(challengeIds[0]);

    return (
      <Notification
        title={store.i18n.rosetta.t('notificationBanner.newChallenges', {
          count: challengeCount,
        })}
        subtitle={store.i18n.rosetta.t('notificationBanner.released', {
          count: challengeCount,
          alias: challenge.alias,
        })}
        icon={<MapPinIcon className="size-5 text-gray-400" aria-hidden="true" />}
      />
    );
  }
  if (notification.type === START)
    return (
      <Notification
        title={store.i18n.rosetta.t('notificationBanner.gameStarted')}
        subtitle={store.i18n.rosetta.t('notificationBanner.beCareful')}
        icon={<PlayIcon className="size-5 text-gray-400" aria-hidden="true" />}
      />
    );
  if (notification.type === GAME_OVER)
    return (
      <Notification
        title={store.i18n.rosetta.t('notificationBanner.gameOver')}
        subtitle={store.i18n.rosetta.t('notificationBanner.goBackToStart')}
        icon={<NoSymbolIcon className="size-5 text-gray-400" aria-hidden="true" />}
      />
    );

  if (notification.type === CHALLENGE_SOLVE) {
    const { isCorrect, points } = notification.metadata;
    return (
      <Notification
        title={`${isCorrect ? '+' : '-'}${Math.abs(points)}xp`}
        subtitle={store.i18n.rosetta.t(
          isCorrect ? 'notificationBanner.solvedCorrectly' : 'notificationBanner.solvedWrongly'
        )}
        icon={
          isCorrect ? (
            <HandThumbUpIcon className="size-5 text-emerald-500" aria-hidden="true" />
          ) : (
            <HandThumbDownIcon className="size-5 text-red-500" aria-hidden="true" />
          )
        }
      />
    );
  }

  return null;
}

const NotificationBanner = observer(function NotificationBanner({ store }) {
  const notification = store.view.activeNotification;

  const clickHandler = () => {
    store.view.hideAllNotifications();
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 flex items-end justify-center p-6"
      >
        <Transition
          show={store.view.notificationIsActive}
          as={Fragment}
          enter="ease-out duration-300 transition"
          enterFrom="translate-y-8 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {notification && (
            <Button
              onClick={clickHandler}
              className="pointer-events-auto flex w-full max-w-64 justify-center rounded-full bg-black/75 shadow-lg ring-1 ring-black/5 backdrop-blur-lg"
            >
              {renderNotification(notification, store)}
            </Button>
          )}
        </Transition>
      </div>
    </>
  );
});

export default NotificationBanner;
