import { observer } from 'mobx-react-lite';
import { MapPinIcon } from '@heroicons/react/24/solid';
import {
  Button,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Transition,
} from '@headlessui/react';

const GeolocationModal = observer(function GeolocationModal({ store }) {
  return (
    <Transition appear show={import.meta.env.PROD && store.view.geoLocationModalIsOpen}>
      <Dialog
        as="div"
        className="relative z-50 focus:outline-none"
        onClose={() => store.view.closeGeolocationModal()}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/80 duration-300 ease-out data-[closed]:opacity-0"
        />
        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="w-full max-w-md rounded-xl bg-white p-6 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
            >
              <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-indigo-100">
                <MapPinIcon className="size-6 text-indigo-600" aria-hidden="true" />
              </div>
              <DialogTitle as="h3" className="mt-2 text-base/7 font-medium text-gray-900">
                {store.i18n.rosetta.t('geoLocModal.title')}
              </DialogTitle>
              <p className="mt-2 text-sm/6 text-gray-500">
                {store.i18n.rosetta.t('geoLocModal.desc')}
              </p>
              <div className="mt-4 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <a
                  href={`https://urbanhunt.be/${store.i18n.locale}/help/locatie?id=${store.urlId}`}
                  className="inline-flex w-full justify-center gap-2 rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white hover:bg-indigo-700 focus:outline-none sm:col-start-2"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  {store.i18n.rosetta.t('geoLocModal.primaryButton')} &rarr;
                </a>
                <Button
                  className="mt-3 inline-flex w-full justify-center gap-2 rounded-md bg-white px-3 py-1.5 text-sm/6 font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-none sm:col-start-1 sm:mt-0"
                  onClick={() => store.view.closeGeolocationModal()}
                >
                  {store.i18n.rosetta.t('geoLocModal.secondaryButton')}
                </Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});

export default GeolocationModal;
