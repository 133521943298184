const EmptyPage = ({ children, ...props }) => (
  <div className="fixed inset-0 flex flex-col items-center justify-around overflow-hidden">
    <div className="flex flex-col items-center">
      <props.icon className="size-24 text-gray-700" />
      <div className="text-center text-lg font-semibold text-gray-400">{children}</div>
    </div>
  </div>
);

export default EmptyPage;
