import { useState, useRef, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/16/solid';
import { useScrollBack } from './useScrollBack';
import { MATCH_STATES } from '../lib/constants';

// const getMedalColor = (position, isHighlighted = false) => {
//   // GOLD medal
//   if (position === 1) return 'bg-amber-300 text-amber-700';
//   // GOLD medal
//   if (position === 2) return 'bg-gray-400 text-gray-700';
//   // BRONZE medal
//   if (position === 3) return 'bg-amber-600 text-amber-900';
//   // No medal, default color
//   return isHighlighted ? 'bg-gray-800 text-white' : 'bg-gray-700 text-white';
// };

const PlayerList = observer(function PlayerList({ players }) {
  if (players.length === 0) return null;
  return (
    <div className="text-[0.65rem] text-gray-400">
      {players.map((player, idx) => (
        <Fragment key={idx}>
          {player}
          {idx < players.length - 1 && <span className="mx-0.5">·</span>}
        </Fragment>
      ))}
    </div>
  );
});

const TeamRow = observer(function TeamRow({ team, store, className }) {
  const { scrollRef } = useScrollBack(7000);

  return (
    <motion.div
      className={clsx(
        'flex justify-between overflow-hidden p-1 text-xs text-gray-100',
        team.isCurrent && store.teamCount > 1 ? 'bg-indigo-600/50' : '',
        className
      )}
      layout
      transition={{
        type: 'spring',
        damping: 25,
        stiffness: 120,
      }}
      key={team.name}
    >
      <div
        className={clsx('mr-1 flex w-4 shrink-0 justify-end', {
          'items-center': store.state < MATCH_STATES.STARTED,
        })}
      >
        {store.state < MATCH_STATES.STARTED ? (
          <div className={clsx(team.isReady ? 'text-emerald-400' : 'text-amber-500')}>
            {team.isReady ? (
              <CheckCircleIcon className="size-3.5" />
            ) : (
              <XCircleIcon className="size-3.5" />
            )}
          </div>
        ) : (
          <div className="text-right font-light text-gray-400">{team.rank}</div>
        )}
      </div>
      <div className="hide-scrollbar right-scroll-mask grow overflow-x-auto pr-3" ref={scrollRef}>
        <div className="inline-block whitespace-nowrap pr-2">
          <div>{team.name}</div>
          <PlayerList players={team.players} />
        </div>
      </div>
      {store.inReviewMode && (
        <div className="w-[1.9rem] shrink-0 text-right text-gray-400">{team.score}</div>
      )}
    </motion.div>
  );
});

const MiniScoreboard = observer(function MiniScoreboard({ store }) {
  const [scrollPos, setScrollPos] = useState({ top: false, bottom: false });
  const scrollRef = useRef(null);

  // update scroll position
  const handleScroll = (elem) => {
    const { scrollHeight, scrollTop, clientHeight } = elem;
    const bottom = scrollHeight - scrollTop <= clientHeight;
    const top = scrollTop <= 0;
    setScrollPos({ top, bottom });
  };

  // check the initial scroll position
  useEffect(() => {
    if (!scrollRef.current) return;
    handleScroll(scrollRef.current);
  }, []);

  return (
    <div
      ref={scrollRef}
      className={clsx('hide-scrollbar max-h-48 overflow-y-scroll', {
        'bottom-scroll-mask': !scrollPos.bottom,
        'top-scroll-mask': !scrollPos.top,
        'scroll-mask': !scrollPos.bottom && !scrollPos.top,
      })}
      onScroll={(e) => handleScroll(e.target)}
    >
      <div className="grid divide-y divide-gray-400/10">
        {store.teamsByRankAsArray.map((team, idx) => {
          const isLastRow = store.teamsByRankAsArray.length === idx + 1;
          const isFirstRow = idx === 0;
          return (
            <TeamRow
              store={store}
              team={team}
              key={team.id}
              className={{ 'pb-2': isLastRow, 'pt-1.5': isFirstRow }}
            />
          );
        })}
      </div>
    </div>
  );
});

export default MiniScoreboard;
