import { makeAutoObservable, observable } from 'mobx';
import { solveChallenge } from '../lib/api';
import { isNumber } from '../lib/utils';
import { MATCH_SETTINGS } from '../lib/constants';

const { POINT_FACTOR, SPEED_BONUS_BASE_POINTS } = MATCH_SETTINGS;

class ChallengeStore {
  id;

  cmsId;

  description;

  trivia;

  explanation;

  question;

  difficulty;

  latitude;

  longitude;

  rootStore;

  points = null;

  answers = [];

  answerIdx;

  correctAnswerIdx = null;

  selectedAnswerIdx = null;

  isCorrect = null;

  foundCount = 0;

  reward = 0;

  isLoading = false;

  constructor(rootStore, id) {
    makeAutoObservable(this, {
      rootStore: false,
      id: false,
      cmsId: false,
      description: false,
      trivia: false,
      explanation: observable.ref,
      question: false,
      difficulty: false,
      latitude: false,
      longitude: false,
      answers: false,
      answerType: false,
    });

    this.rootStore = rootStore;
    this.id = id;
  }

  get alias() {
    const nr = this.id + 1;
    return nr === 0 ? 'demo' : nr;
  }

  get isDemo() {
    return this.id === -1;
  }

  get hasBeenAnswered() {
    return isNumber(this.answerIdx);
  }

  get answerType() {
    return this.answers[0]._type;
  }

  get showOnMap() {
    return !this.hasBeenAnswered || this.rootStore.inReviewMode;
  }

  // can we show the correct solution to the player?
  get canBeSpoiled() {
    return this.hasBeenAnswered || this.rootStore.inReviewMode;
  }

  get canBeSolved() {
    return !this.hasBeenAnswered && !this.rootStore.inReviewMode;
  }

  get coordinates() {
    return [this.longitude, this.latitude];
  }

  get basePoints() {
    return this.difficulty * POINT_FACTOR;
  }

  // speed bonus depends on the nr of teams in a session:
  get speedBonus() {
    const { teamCount } = this.rootStore;

    // 1 team: 0
    if (this.isDemo || teamCount <= 1) return 0;

    const factorMap = [
      // 2 teams: 30 - 15 - 0
      { threshold: 3, factor: 15 },
      // 3 teams: 30 - 20 - 10
      { threshold: 4, factor: 10 },
      // 4 teams: 30 - 22 - 14 - 6
      { threshold: 5, factor: 8 },
      // 5 teams: 30 - 23 - 16 - 9 - 2
      { threshold: 6, factor: 7 },
      // 6 teams: 30 - 24 - 18 - 12 - 6 - 0
      { threshold: 7, factor: 6 },
      // 7 teams: 30 - 25 - 20 - 15 - 10 - 5 - 0
      { threshold: 8, factor: 5 },
      // 8 teams: 30 - 26 - 22 -18 - 14 - 10 - 6 - 2
      { threshold: 9, factor: 4 },
      // 9 till 14 teams: 30 - 27 - 24 - 21 - 18 - 15 - 12 - 9 - 6 - 3 - 0
      { threshold: 15, factor: 3 },
      // From 15 teams: 30 - 28 - 26 - 24 - 22 - 20 - 18 - 16 - 14 - 12 - 10 - 8 - 6 - 4 - 2 - 0
      { threshold: Infinity, factor: 2 },
    ];
    const { factor } = factorMap.find(({ threshold }) => teamCount < threshold);

    // never a negative bonus
    return Math.max(SPEED_BONUS_BASE_POINTS - factor * this.foundCount, 0);
  }

  get totalPoints() {
    return this.basePoints + this.speedBonus + this.reward;
  }

  get correctAnswer() {
    if (!this.canBeSpoiled || this.correctAnswerIdx === null) return null;
    return this.answers[this.correctAnswerIdx];
  }

  get asJson() {
    return {
      id: this.id,
      answerIdx: this.answerIdx,
      points: this.points,
      isCorrect: this.isCorrect,
      reward: this.reward,
      foundCount: this.foundCount,
      totalPoints: this.totalPoints,
    };
  }

  async solve() {
    if (!this.canBeSolved || this.isLoading || this.selectedAnswerIdx === null) return;

    this.setIsLoading(true);

    try {
      const response = await solveChallenge(this.id, {
        answerIdx: this.selectedAnswerIdx,
        teamId: this.rootStore.currentTeam.id,
      });
      if (response) {
        const { answer, team, challenge } = response;
        this.updateFromJson({
          answerIdx: this.selectedAnswerIdx,
          points: answer.points,
          isCorrect: answer.isCorrect,
          foundCount: challenge.foundCount,
          reward: challenge.reward,
        });
        this.rootStore.currentTeam.setScore(team.score);
        this.deselectAnswer();
        this.rootStore.view.openChallengeFeedbackModal();
        if (answer.isCorrect) this.rootStore.view.shootConfetti();
      }
    } finally {
      // Any steps after await aren't in the same tick, so they require action wrapping.
      this.setIsLoading(false);
    }
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  selectAnswer(answerIdx) {
    if (!this.canBeSolved) return;
    this.selectedAnswerIdx = answerIdx;
  }

  deselectAnswer() {
    this.selectAnswer(null);
  }

  updateFromJson(json) {
    Object.keys(json).forEach((key) => {
      this[key] = json[key];
    });
  }
}

export default ChallengeStore;
