const URL = import.meta.env.VITE_API_URL;

const get = async (path = '/') => {
  const response = await fetch(`${URL}${path}`);
  return response.status === 200 ? response.json() : false;
};

const post = async (path = '/', data) => {
  const response = await fetch(`${URL}${path}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.status === 200 ? response.json() : false;
};

export const getTeamById = (id) => get(`/teams/${id}`);

export const solveChallenge = (challengeId, data) => post(`/challenges/${challengeId}/solve`, data);

export const setIsReady = (teamId, isReady) => post(`/teams/${teamId}/ready`, { isReady });
