import { keyMirror } from './utils';

export const DEFAULT_LOCALE = 'nl';

export const MATCH_STATES = {
  // all teams need to mark themselves as ready before the match continues
  SETUP: 0,
  // the match is counting down and preparing to start
  COUNTDOWN: 1,
  // the match has started, all game functions can be used now.
  STARTED: 2,
  // the match is over, the match is read only now, designed to review challenges and answers
  GAME_OVER: 3,
  // the match cannot be opened anymore and is archived (removed from memory, in DB only)
  // EXPIRED: 4,
};

export const MATCH_SETTINGS = {
  // 30 seconds
  COUNTDOWN_IN_MS: 30 * 1000,
  // 95 minutes
  DURATION_IN_MS: 95 * 60 * 1000,
  POINT_FACTOR: 10,
  SPEED_BONUS_BASE_POINTS: 30,
};

export const OP_CODES = {
  HELLO: 0,
  HEARTBEAT: 1,
  HEARTBEAT_ACK: 2,
  IDENTIFY: 3,
  GAME_ACTION: 4,
};

export const DEFAULT_MAP_ZOOM = 14;

export const WS_EVENTS = keyMirror([
  'SYNC',
  'MATCH_UPDATE',
  'MATCH_START',
  'MATCH_START',
  'TEAM_UPDATE',
  'CHALLENGE_UPDATE',
  'CHALLENGE_REVEAL',
  'NOTIFICATION_CREATE',
  'USER_LOCATION_UPDATE',
]);

export const NOTIFICATION_TYPES = {
  MESSAGE: 1,
  CHALLENGE_REVEAL: 2,
  CHALLENGE_SOLVE: 3,
  SCOREBOARD_HIDE: 4,
  GAME_OVER: 5,
  START: 6,
};

export const URL_PATHS = {
  feed: '/feed',
  teams: '/teams',
  challenge: '/challenges',
  map: '/',
};

// used for local storage
export const LS_KEY_PREFIX = 'uh';
