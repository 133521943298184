import { makeObservable, observable, action } from 'mobx';
// import i18n from '../lib/i18n';
import rosetta from 'rosetta';
import { DEFAULT_LOCALE } from '../lib/constants';

class I18nStore {
  locale = DEFAULT_LOCALE;

  isReady = false;

  rosetta;

  constructor() {
    makeObservable(this, {
      isReady: observable,
      setIsReady: action,
      locale: false,
      rosetta: false,
    });
    this.rosetta = rosetta();
  }

  initLocale(locale) {
    this.locale = locale;
    this.rosetta.locale(locale);
    import(`../locales/${locale}.js`).then(({ default: dict }) => {
      this.rosetta.set(locale, dict);
      this.setIsReady(true);
    });
  }

  setIsReady(isReady) {
    this.isReady = isReady;
  }
}

export default I18nStore;
