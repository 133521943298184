import { StoreProvider } from './components/StoreProvider';
import Play from './components/Play';

function App({ store }) {
  return (
    <StoreProvider store={store}>
      <Play />
    </StoreProvider>
  );
}

export default App;
