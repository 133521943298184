/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { RadioGroup, Button, Label, Radio, Field, Description } from '@headlessui/react';
import { MagnifyingGlassPlusIcon, PlayIcon } from '@heroicons/react/20/solid';
import Badge from './Badge';
import { usePhotoSwipe } from './usePhotoSwipe';
import { imageBuilder } from '../lib/sanity';
import { getVimeoId } from '../lib/vimeo';
import { getLetterByIdx } from '../lib/utils';

const RenderBadge = observer(function RenderBadge({
  idx,
  challenge,
  correctComponent,
  wrongComponent,
}) {
  const answeredByTeam = challenge.hasBeenAnswered && challenge.answerIdx === idx;

  if (challenge.canBeSpoiled && challenge.correctAnswerIdx === idx) {
    return correctComponent;
  }
  if (answeredByTeam) {
    return challenge.isCorrect ? correctComponent : wrongComponent;
  }

  return null;
});

const VisualAnswers = observer(function VisualAnswers({
  store,
  renderChild,
  buttonClickHandler,
  buttonChildren,
  galleryId,
}) {
  const challenge = store.view.activeChallenge;

  const toggleAnswerSelection = (idx) => {
    if (challenge.selectedAnswerIdx === null || challenge.selectedAnswerIdx !== idx)
      challenge.selectAnswer(idx);
    else challenge.deselectAnswer();
  };

  return (
    <ul className="grid grid-cols-2 gap-2.5">
      {challenge.answers.map((answer, idx) => {
        const isSelected = challenge.selectedAnswerIdx === idx;
        const isHighlighted = challenge.hasBeenAnswered ? challenge.answerIdx === idx : isSelected;

        return (
          <li
            key={answer._key}
            className={clsx({
              'rounded ring-[3px] ring-offset-2 ring-offset-gray-900': isHighlighted,
              'ring-indigo-600': !challenge.hasBeenAnswered,
              'ring-emerald-600': challenge.isCorrect === true,
              'ring-red-600': challenge.isCorrect === false,
            })}
          >
            <div className="relative flex flex-col gap-0.5">
              <Button onClick={() => toggleAnswerSelection(idx)}>
                <div className="overflow-hidden rounded-t bg-gray-600" id={galleryId}>
                  {renderChild({ answer: answer.answer, idx, challenge })}
                </div>
              </Button>
              <Button
                type="button"
                onClick={() => buttonClickHandler(idx)}
                className={clsx(
                  'inline-flex w-full items-center justify-center gap-x-2 rounded-b border border-transparent px-2.5 py-2 text-sm font-medium text-white',
                  isSelected ? 'bg-indigo-600' : 'bg-gray-800'
                )}
              >
                {buttonChildren ?? (
                  <>
                    <MagnifyingGlassPlusIcon className="-ml-0.5 size-4" aria-hidden="true" />
                    <span>{store.i18n.rosetta.t('challenge.zoom')}</span>
                  </>
                )}
              </Button>
              <div
                className={clsx(
                  'pointer-events-none absolute left-0 top-0 m-1.5 flex size-7 items-center justify-center overflow-hidden rounded-full text-lg font-bold text-white backdrop-blur-sm',
                  isSelected ? 'bg-indigo-600' : 'bg-black/50'
                )}
              >
                {/* the letters B and D are not aligned well in the circle, let's visually center these better */}
                <span
                  className={clsx({
                    'ml-[0.095rem]': idx === 3,
                    'ml-[0.045rem]': idx === 1,
                  })}
                >
                  {getLetterByIdx(idx)}
                </span>
              </div>
              <RenderBadge
                idx={idx}
                challenge={challenge}
                correctComponent={
                  <Badge className="absolute right-0 top-0 m-1.5 rounded bg-emerald-600 text-center text-white">
                    {store.i18n.rosetta.t('challenge.correct')}
                  </Badge>
                }
                wrongComponent={
                  <Badge className="absolute right-0 top-0 m-1.5 rounded bg-red-600 text-center text-white">
                    {store.i18n.rosetta.t('challenge.wrong')}
                  </Badge>
                }
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
});

export const TextAnswers = observer(function TextAnswers({ store }) {
  const challenge = store.view.activeChallenge;

  return (
    <RadioGroup
      value={challenge.hasBeenAnswered ? challenge.answerIdx : challenge.selectedAnswerIdx}
      onChange={(idx) => challenge.selectAnswer(idx)}
    >
      <Label className="sr-only">Antwoordopties</Label>
      <div className="divide-y divide-gray-700 overflow-hidden rounded-md border border-gray-700 bg-gray-800">
        {challenge.answers.map((answer, idx) => {
          return (
            <Field key={answer._key}>
              <Radio
                value={idx}
                className="group relative flex p-4 transition duration-300 focus:outline-none data-[checked]:bg-indigo-600"
              >
                <div className="flex w-full items-center">
                  <span
                    className="flex size-4 shrink-0 items-center justify-center rounded-full border-2 border-gray-600 bg-gray-700 group-data-[checked]:border-indigo-200 group-data-[checked]:bg-indigo-500"
                    aria-hidden="true"
                  >
                    <span className="size-1.5 rounded-full bg-gray-700 transition group-data-[checked]:bg-white" />
                  </span>
                  <div className="ml-3 flex-1">
                    <Label
                      as="div"
                      className="flex flex-wrap justify-between font-medium text-gray-100 group-data-[checked]:text-white"
                    >
                      <Description>{answer.answer}</Description>
                      <div className="shrink-0">
                        <RenderBadge
                          idx={idx}
                          challenge={challenge}
                          correctComponent={
                            <Badge className="bg-emerald-600 text-white">
                              {store.i18n.rosetta.t('challenge.correct')}
                            </Badge>
                          }
                          wrongComponent={
                            <Badge className="bg-red-600 text-white">
                              {store.i18n.rosetta.t('challenge.wrong')}
                            </Badge>
                          }
                        />
                      </div>
                    </Label>
                  </div>
                </div>
              </Radio>
            </Field>
          );
        })}
      </div>
    </RadioGroup>
  );
});

export const ImageAnswers = observer(function ImageAnswers(props) {
  const challenge = props.store.view.activeChallenge;

  const lightbox = usePhotoSwipe({
    dataSource: challenge.answers.map(({ answer }, idx) => {
      const { width, height } = answer.asset.metadata.dimensions;
      return {
        src: imageBuilder.image(answer).width(1920).url(),
        width,
        height,
        caption: `${props.store.i18n.rosetta.t('challenge.answer')} ${getLetterByIdx(idx)}`,
      };
    }),
  });

  const openLightboxAtSlideIdx = (idx) => {
    lightbox.loadAndOpen(idx);
  };

  return (
    <VisualAnswers
      {...props}
      buttonClickHandler={openLightboxAtSlideIdx}
      renderChild={({ answer }) => {
        const thumbSrc = imageBuilder.image(answer).width(600).url();
        return (
          <div
            style={{ backgroundImage: `url(${thumbSrc}` }}
            className="flex aspect-[10/8] items-center justify-center bg-cover bg-center"
          />
        );
      }}
    />
  );
});

export const VideoAnswers = observer(function VideoAnswers(props) {
  const challenge = props.store.view.activeChallenge;

  const lightbox = usePhotoSwipe({
    dataSource: challenge.answers.map(({ answer }, idx) => {
      // const vimeoId = getVimeoId(answer.url);
      return {
        caption: `${props.store.i18n.rosetta.t('challenge.answer')} ${getLetterByIdx(idx)}`,
        embedUrl: `${answer.url}&playsinline=1`,
      };
    }),
  });

  useEffect(() => {
    if (!lightbox) return;

    lightbox.on('contentLoad', async (e) => {
      e.preventDefault();
      const { content } = e;
      if (content.element) return;
      const { embedUrl } = content.data;

      const wrapper = document.createElement('div');
      const aspectRatioWrapper = document.createElement('div');
      const iframe = document.createElement('iframe');
      wrapper.className = 'flex items-center justify-center pointer-events-none';
      aspectRatioWrapper.className =
        'relative w-[100vmin] h-[56.25vmin] m-auto flex-initial bg-gray-800';
      iframe.className = 'absolute inset-0 w-full h-full pointer-events-auto';
      iframe.setAttribute('playsinline', '');
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('webkitallowfullscreen', '');
      iframe.setAttribute('mozallowfullscreen', '');
      iframe.setAttribute('allowfullscreen', '');
      iframe.src = `${embedUrl}&playsinline=1`;
      content.element = wrapper;
      aspectRatioWrapper.appendChild(iframe);
      content.element.appendChild(aspectRatioWrapper);
    });
  }, [lightbox]);

  const openLightboxAtSlideIdx = (idx) => {
    lightbox.loadAndOpen(idx);
  };
  return (
    <VisualAnswers
      {...props}
      galleryId="zoomable-video"
      buttonClickHandler={openLightboxAtSlideIdx}
      buttonChildren={
        <>
          <PlayIcon className="-ml-0.5 size-4" aria-hidden="true" />
          <span>{props.store.i18n.rosetta.t('challenge.play')}</span>
        </>
      }
      renderChild={({ answer }) => {
        const vimeoId = getVimeoId(answer.url);
        const thumbnailSrc = `https://urbanhunt.be/api/vimeo/${vimeoId}`;
        return (
          <div
            style={{ backgroundImage: `url(${thumbnailSrc}` }}
            className="flex aspect-video items-center justify-center bg-cover bg-center"
          />
        );
      }}
    />
  );
});
