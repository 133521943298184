import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Button, Dialog, DialogPanel, DialogBackdrop, Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon, UserGroupIcon } from '@heroicons/react/24/solid';
import { TrophyIcon } from '@heroicons/react/16/solid';
// import HighlightedText from './HighlightedText';

const PointsBadge = observer(function PointsBadge({ challenge, unit }) {
  return (
    <div
      className={clsx(
        'inline-flex items-center rounded-full pr-3 ring-4 ring-white/20',
        challenge.isCorrect ? 'bg-emerald-100 text-emerald-800' : 'bg-red-100 text-red-800'
      )}
    >
      {challenge.isCorrect ? (
        <CheckCircleIcon className="size-12 text-emerald-500" />
      ) : (
        <XCircleIcon className="size-12 text-red-500" />
      )}
      <span className="ml-1 text-xl font-bold">
        {challenge.points} {unit}
      </span>
    </div>
  );
});

const ChallengeFeedbackOverlay = observer(function ChallengeFeedbackOverlay({ store }) {
  const challenge = store.view.activeChallenge;

  if (!challenge) return null;

  function close(scrollToExplanation = false) {
    store.view.closeChallengeFeedbackModal();

    if (!scrollToExplanation) return;

    setTimeout(() => {
      document.querySelector(`#explanation`).scrollIntoView({
        behavior: 'smooth',
      });
    }, 750);
  }

  return (
    <Transition appear show={store.view.challengeFeedbackModalIsOpen}>
      <Dialog as="div" className="relative z-50 focus:outline-none" onClose={() => {}}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop
          transition
          className={clsx(
            'fixed inset-0 backdrop-blur-sm duration-300 ease-out data-[closed]:opacity-0',
            challenge.isCorrect ? 'bg-emerald-900/80' : 'bg-red-900/80'
          )}
        />
        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className={clsx(
                'flex w-full max-w-md flex-col overflow-hidden rounded-2xl bg-gray-900 shadow-xl duration-300 ease-out data-[closed]:scale-75 data-[closed]:opacity-0',
                challenge.isCorrect ? 'shadow-emerald-900' : 'shadow-red-900'
              )}
            >
              <div
                className={clsx(
                  'flex justify-center py-5',
                  challenge.isCorrect ? 'bg-emerald-500' : 'bg-red-500'
                )}
              >
                <PointsBadge
                  challenge={challenge}
                  unit={store.i18n.rosetta.t('feedbackModal.points')}
                />
              </div>
              <div className="p-4">
                {store.teamCount > 0 && (
                  <div className="mt-2 flex flex-col gap-y-2 text-gray-200">
                    {store.showScoreboard && (
                      <div className="flex items-start gap-x-0.5">
                        <TrophyIcon className="mr-1 mt-0.5 size-5 shrink-0 text-gray-400" />
                        <span>
                          {store.i18n.rosetta.t('feedbackModal.rank', {
                            rank: store.currentTeam.rank,
                          })}
                        </span>
                      </div>
                    )}
                    <div className="flex items-start gap-x-0.5">
                      <UserGroupIcon className="mr-1 mt-0.5 size-5 shrink-0 text-gray-400" />
                      {challenge.isCorrect ? (
                        <span>
                          {store.i18n.rosetta.t('feedbackModal.solvedRank', {
                            rank: challenge.foundCount,
                          })}
                        </span>
                      ) : (
                        <span>
                          {store.i18n.rosetta.t('feedbackModal.solvedByTeams', {
                            count: challenge.foundCount,
                          })}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <div className={clsx('flex gap-x-2', { 'mt-6': store.teamCount > 0 })}>
                  <Button
                    onClick={() => close(false)}
                    className="inline-flex w-full items-center justify-center rounded-md bg-gray-700 px-3 py-2 text-sm/6 font-semibold text-white focus:outline-none"
                  >
                    {store.i18n.rosetta.t('feedbackModal.secondaryButton')}
                  </Button>
                  <Button
                    onClick={() => close(true)}
                    className="inline-flex w-full items-center justify-center rounded-md bg-gray-100 px-3 py-2 text-sm/6 font-semibold text-gray-900 focus:outline-none"
                  >
                    {store.i18n.rosetta.t('feedbackModal.primaryButton')} &darr;
                  </Button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});

export default ChallengeFeedbackOverlay;
