import { observer } from 'mobx-react-lite';

const CountdownClock = observer(function CountdownClock({ store }) {
  return (
    <div className="flex items-center justify-center bg-gray-900/50 font-medium leading-8 text-gray-100">
      <div>
        <span>{store.timeLeftFormatted.hours}</span>
        <span className="pr-1 text-xs text-gray-400">{store.i18n.rosetta.t('clock.h')}</span>
        <span>{store.timeLeftFormatted.minutes}</span>
        <span className="pr-1 text-xs text-gray-400">m</span>
        <span>{store.timeLeftFormatted.seconds}</span>
        <span className="text-xs text-gray-400">s</span>
      </div>
    </div>
  );
});

export default CountdownClock;
