/* eslint-disable no-restricted-globals */
import { reaction } from 'mobx';
import Router from 'minimal-router';

let disposer;

export const getUrlId = () => {
  const id = window.location.pathname.split('/')[1];
  return id.length === 5 ? id : null;
};

export function startRouter(store) {
  const router = new Router();
  router.setPrefix(`/${store.urlId}`);

  // routes
  router.add('/challenges/:id', ({ params }) => {
    // the id url param is not zero index based
    // -> we need to subtract 1 to find the challenge by id
    const challengeId = Number(params.id) - 1;
    const challenge = store.challenges.get(challengeId);
    if (challenge) store.view.openChallengeInPanel(challenge);
    else store.view.closePanel();
  });
  router.add('/', () => {
    store.view.closePanel();
  });

  const dispatchCurrentPath = () => {
    const path = window.location.pathname.replace(`/${store.urlId}`, '');
    if (!router.dispatch(path)) store.view.setScreen('map');
  };

  dispatchCurrentPath();
  // Listen browser event for back navigation
  window.onpopstate = () => dispatchCurrentPath();

  // for dev purposes
  // if (import.meta.env.PROD) window.router = router;

  // update url on state changes, we don't need to dispose of this reaction
  disposer = reaction(
    () => store.view.urlPath,
    (path) => {
      const pathname = `/${store.urlId}${path}`;
      if (pathname !== window.location.pathname) window.history.pushState(null, null, pathname);
    },
    { fireImmediately: true }
  );

  return router;
}

export function stopRouter() {
  disposer();
  window.onpopstate = () => {};
}
