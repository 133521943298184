import { useEffect } from 'react';

const ChatwootWidget = ({ store }) => {
  useEffect(() => {
    // Add Chatwoot Settings
    window.chatwootSettings = {
      hideMessageBubble: true,
      position: 'right', // This can be left or right
      locale: 'nl', // Language to be set
      type: 'standard', // [standard, expanded_bubble]
      darkMode: 'auto',
    };

    // Paste the script from inbox settings except the <script> tag
    /* eslint-disable */
    (function (d, t) {
      var BASE_URL = 'https://chat.urbanhunt.be';
      var g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + '/packs/js/sdk.js';
      s.parentNode.insertBefore(g, s);
      g.async = !0;
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: 'W7Vd6D6Gx6BrNMRcqPigHbH8',
          baseUrl: BASE_URL,
        });
      };
    })(document, 'script');

    function setData() {
      // try and send custom data coming from our MOBX ROOT STORE
      if (!store || !store.isReady) return;

      window.$chatwoot.setLocale(store.i18n.locale);
      window.$chatwoot.setLabel('game');
      window.$chatwoot.setUser(store.currentTeam.id, {
        name: store.currentTeam.name,
      });
      window.$chatwoot.setCustomAttributes({ game: store.game.name });
    }

    window.addEventListener('chatwoot:ready', setData);

    // Cleanup function
    return () => {
      window.removeEventListener('chatwoot:ready', setData);
    };
  }, []);

  return null;
};

export default ChatwootWidget;
