import { PortableText } from '@portabletext/react';
import { imageBuilder } from '../lib/sanity';
import { usePhotoSwipe } from './usePhotoSwipe';

const myPortableTextComponents = {
  types: {
    vimeo: ({ value }) => {
      return (
        <span
          className="relative my-4 block aspect-video rounded bg-gray-800 shadow"
          key={value._key}
        >
          <iframe
            src={value.url}
            frameBorder="0"
            allow="fullscreen; picture-in-picture"
            allowFullScreen
            className="absolute left-0 top-0 size-full overflow-hidden rounded"
            webkitallowfullscreen="true"
            title="Urban Hunt Video"
            mozallowfullscreen="true"
          />
        </span>
      );
    },
    image: ({ value }) => {
      const { metadata } = value.asset;
      const { width, height } = metadata.dimensions;
      return (
        <>
          <a
            href={imageBuilder.image(value).width(1800).url()}
            data-zoom-portable-text
            data-pswp-width={width}
            data-pswp-height={height}
            target="_blank"
            rel="noreferrer"
            className="relative !my-2 block overflow-hidden rounded bg-gray-800"
            style={{ paddingBottom: `${(height / width) * 100}%` }}
          >
            <img
              className="not-prose absolute my-0 size-full"
              src={imageBuilder.image(value).width(800).url()}
              // alt={metadata.originalFilename}
            />
          </a>
          {/* {fields.description && <span className="italic text-gray-300">{fields.description}</span>} */}
        </>
      );
    },
  },
};

export default function CustomPortableText({ value }) {
  usePhotoSwipe({
    gallerySelector: 'a[data-zoom-portable-text]',
  });
  return <PortableText value={value} components={myPortableTextComponents} />;
}
