import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { observer } from 'mobx-react-lite';
import mapboxgl from 'mapbox-gl';
// import clsx from 'clsx';
import MapMarker from './MapMarker';
import personWalkingSrc from '../img/person-walking.png';
import { throttle } from '../lib/utils';
import { DEFAULT_MAP_ZOOM, OP_CODES, WS_EVENTS } from '../lib/constants';

mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

const Map = observer(function Map({ store }) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/rova/clf89v4ny00c201mrpksiknkg',
      zoom: DEFAULT_MAP_ZOOM,
      center: store.map.defaultCenter,
      touchPitch: false,
      // maxBounds: store.map.maxBounds,
    });

    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      // When active the map will receive updates to the device's location as it changes.
      trackUserLocation: true,
      // Draw an arrow next to the location dot to indicate which direction the device is heading.
      showUserHeading: true,
    });

    // add map scale
    map.current.addControl(new mapboxgl.ScaleControl());
    // Add geolocate control to the map.
    map.current.addControl(geolocate, 'bottom-right');
    // disable map rotation using right click + drag
    map.current.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();

    // draw markers as soon as the map is visually ready
    map.current.on('load', () => {
      // custom team symbol
      map.current.loadImage(personWalkingSrc, (error, image) => {
        if (error) throw error;
        map.current.addImage('person-walking', image);
      });

      // link the mapbox with our MobX store
      store.map.linkMapbox(map.current);

      store.map.paintMarkers((challenge, htmlEl) => {
        ReactDOM.createRoot(htmlEl).render(<MapMarker challenge={challenge} />);
      });

      // show a geolocation explanation modal if geolocation is blocked
      geolocate.on('error', (err) => {
        // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
        // code 1: The acquisition of the geolocation information failed because the page didn't have the necessary permissions
        if (err.code === 1) store.view.openGeolocationModal();
      });

      geolocate.on(
        'geolocate',
        throttle((data) => {
          console.log('sending geo data', data.coords.latitude, data.coords.longitude);
          store.sync.send({
            op: OP_CODES.GAME_ACTION,
            t: WS_EVENTS.USER_LOCATION_UPDATE,
            d: {
              latitude: data.coords.latitude,
              longitude: data.coords.longitude,
              accuracy: data.coords.accuracy,
            },
          });
        }, 10000)
      );

      geolocate.trigger();
    });

    // eslint-disable-next-line consistent-return
    return () => store.map.unlinkMapbox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="fixed inset-0 bg-gray-100" ref={mapContainer} />;
});

export default Map;
