import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
// import { configure } from "mobx";
import 'mapbox-gl/dist/mapbox-gl.css';
import './fonts.css';
import './index.css';
import App from './App';
import Error from './components/Error';
import RootStore from './stores/RootStore';
import { getUrlId } from './lib/router';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [],
  enabled: import.meta.env.PROD,
});

// MOBX strict mode
// configure({
//   enforceActions: 'observed',
//   computedRequiresReaction: true,
//   reactionRequiresObservable: true,
//   observableRequiresReaction: true,
// });

const urlId = getUrlId();
const store = new RootStore(urlId);

if (!import.meta.env.PROD) window.store = store;

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <Error>
          Something went wrong.
          <br />
          Contact us at info@urbanhunt.be
        </Error>
      }
    >
      <App store={store} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
