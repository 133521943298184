import clsx from 'clsx';
import { StarIcon } from '@heroicons/react/20/solid';

export const StarsIcon = ({ count = 3, maxCount = 3, className = 'w-6 h-6' }) => (
  <div className="flex justify-center">
    {[...Array(maxCount)].map((_, idx) => (
      <StarIcon className={clsx(className, { 'opacity-30': idx >= count })} key={idx} />
    ))}
  </div>
);

export const MedalIcon = (props) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M273.5 152.1H48l54.1-103.9h240.7z" />
      <circle cx="256.2" cy="377.2" r="86.6" />
      <path d="M348.9 299.1l115.1-147-69.3-103.9L256.4 256c32.3 0 62.7 12.7 85.5 35.5 2.5 2.5 4.8 5 7 7.6zM205.8 266.6L152.3 186H48.4l90.1 161.5c5.2-21.2 16.1-40.6 32-56.4 10.4-10.3 22.3-18.6 35.3-24.5z" />
    </svg>
  );
};

export const LoaderIcon = (props) => {
  return (
    <svg
      className={clsx('animate-spin', props.className ?? 'size-6')}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};
