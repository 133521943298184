import { makeAutoObservable } from 'mobx';
import { MATCH_STATES, URL_PATHS } from '../lib/constants';

const { SETUP, COUNTDOWN } = MATCH_STATES;

class ViewStore {
  screen = 'loading';

  activeChallenge = null;

  activeNotification = null;

  notificationIsActive = false;

  geoLocationModalIsOpen = false;

  challengeFeedbackModalIsOpen = false;

  panelIsOpen = false;

  panelScreen = '';

  notificationTimeoutId = null;

  constructor(rootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      notificationTimeoutId: false,
      setupAutoSyncWithLocalStorage: false,
      shootConfetti: false,
    });
    this.rootStore = rootStore;
  }

  get showReadyBanner() {
    return (
      this.rootStore.state === SETUP && !(this.panelIsOpen && this.panelScreen === 'challenge')
    );
  }

  get showCountdownOverlay() {
    return this.rootStore.state === COUNTDOWN;
  }

  get urlPath() {
    if (this.panelIsOpen) {
      const suffix =
        this.panelScreen === 'challenge' && this.activeChallenge
          ? // we want cleaner urls, we add 1 to the id
            `/${this.activeChallenge.id + 1}`
          : '';
      return `${URL_PATHS[this.panelScreen]}${suffix}`;
    }
    return URL_PATHS[this.screen] || '/';
  }

  setScreen(screen) {
    // make sure to always close the panel
    this.closePanel();
    this.screen = screen;
  }

  async shootConfetti() {
    const confetti = (await import('canvas-confetti')).default;
    confetti({ disableForReducedMotion: true, spread: 70, particleCount: 100 });
  }

  openPanelScreen(screen) {
    this.panelScreen = screen;
    this.panelIsOpen = true;
  }

  closePanel() {
    this.panelIsOpen = false;
  }

  openChallengeInPanel(challenge) {
    this.activeChallenge = challenge;
    this.openPanelScreen('challenge');
  }

  openGeolocationModal() {
    this.geoLocationModalIsOpen = true;
  }

  closeGeolocationModal() {
    this.geoLocationModalIsOpen = false;
  }

  openChallengeFeedbackModal() {
    this.challengeFeedbackModalIsOpen = true;
  }

  closeChallengeFeedbackModal() {
    this.challengeFeedbackModalIsOpen = false;
  }

  hideAllNotifications() {
    this.notificationIsActive = false;
  }

  activateNotification(notification) {
    this.activeNotification = notification;
    this.notificationIsActive = true;
  }

  showNotification(notification, showForMs = 5000) {
    if (!notification) return;

    if (this.notificationTimeoutId) {
      clearTimeout(this.notificationTimeoutId);
      this.notificationTimeoutId = null;
      this.hideAllNotifications();
      // we need to delay showing the notification for the hide animation to work
      setTimeout(() => {
        this.activateNotification(notification);
      }, 300);
    } else {
      this.activateNotification(notification);
    }
    this.notificationTimeoutId = setTimeout(() => {
      this.hideAllNotifications();
      this.notificationTimeoutId = null;
    }, showForMs);
  }
}

export default ViewStore;
