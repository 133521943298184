export const getClient = (store) => {
  if (window.$chatwoot?.hasLoaded !== true) return null;

  if (store && store.view.activeChallenge) {
    window.$chatwoot.setCustomAttributes({
      challenge: `https://studio.urbanhunt.be/desk/challenge;${store.view.activeChallenge.cmsId}`,
    });
  }

  return window.$chatwoot;
};
