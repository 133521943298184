import { observer } from 'mobx-react-lite';
import Panel from './Panel';
import Challenge from './Challenge';
import MapMarker from './MapMarker';

const getTitle = (store) => {
  const challenge = store.view.activeChallenge;
  const screen = store.view.panelScreen;
  if (screen === 'challenge' && challenge)
    return challenge.isDemo
      ? challenge.alias
      : store.i18n.rosetta.t('panel.challenge', { alias: challenge.alias });
  return null;
};

const getSubtitle = (store) => {
  const challenge = store.view.activeChallenge;
  const screen = store.view.panelScreen;
  const { t } = store.i18n.rosetta;
  if (screen === 'challenge') return t('panel.solvedCount', { count: challenge.foundCount });
  return null;
};

const getExtraHeader = (store) => {
  const challenge = store.view.activeChallenge;
  if (store.view.panelScreen !== 'challenge' || !challenge) return null;
  return <MapMarker challenge={challenge} alwaysShow />;
};

const PanelWithActiveScreen = observer(function PanelWithActiveScreen({ store }) {
  return (
    <Panel
      isOpen={store.view.panelIsOpen}
      title={getTitle(store)}
      subtitle={getSubtitle(store)}
      rightHeader={getExtraHeader(store)}
    >
      {store.view.panelScreen === 'challenge' && store.view.activeChallenge && (
        <Challenge store={store} />
      )}
    </Panel>
  );
});

export default PanelWithActiveScreen;
