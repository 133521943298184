import { Button } from '@headlessui/react';
import { FlagIcon } from '@heroicons/react/20/solid';
import { getClient } from '../lib/chatwoot';

const FeedbackWidget = ({ store }) => {
  const openChatSupport = () => {
    const cw = getClient(store);
    if (cw) cw.toggle('open');
  };

  return (
    <div className="flex flex-col items-center">
      <div className="text-sm text-gray-500">{store.i18n.rosetta.t('feedback.somethingWrong')}</div>
      <div className="mt-1.5 text-gray-400">
        <Button
          className="flex items-center rounded-md bg-gray-800 px-3 py-0.5 text-sm"
          onClick={() => openChatSupport()}
        >
          <FlagIcon className="mr-1.5 size-4 text-indigo-600" />
          {store.i18n.rosetta.t('feedback.report')}
        </Button>
      </div>
    </div>
  );
};

export default FeedbackWidget;
