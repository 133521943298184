/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
export function keyMirror(arr) {
  const tmp = Object.create(null);
  // eslint-disable-next-line no-restricted-syntax
  for (const value of arr) tmp[value] = value;
  return tmp;
}

export const isNumber = (val) => typeof val === 'number';

export const pluralize = (val, word, plural = `${word}s`) => {
  const _pluralize = (num, word, plural = `${word}s`) =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === 'object') return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
};

export const timeSince = (ms) => {
  const seconds = Math.floor(ms / 1000);
  let interval = seconds / 3600;
  if (interval >= 1) {
    return { value: Math.floor(interval), unit: 'hours' };
  }
  interval = seconds / 60;
  if (interval >= 1) {
    return { value: Math.floor(interval), unit: 'minutes' };
  }
  return { value: Math.floor(seconds), unit: 'seconds' };
};

export const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const pad = (num, size) => {
  num = num.toString();
  while (num.length < size) num = `0${num}`;
  return num;
};

export const formatOrdinalsInLocale = (nr, locale, withNr = false, isFeminine = false) => {
  const suffixes = {
    nl: new Map([['other', 'e']]),
    fr: new Map([
      ['one', 'er'],
      ['other', 'e'],
    ]),
    en: new Map([
      ['one', 'st'],
      ['two', 'nd'],
      ['few', 'rd'],
      ['other', 'th'],
    ]),
  };
  if (!locale || !suffixes[locale]) throw new Error('Locale not available');
  const rules = new Intl.PluralRules(locale, { type: 'ordinal' });
  const rule = rules.select(nr);
  let suffix = suffixes[locale].get(rule);
  // check feminine
  if (locale === 'fr' && rule === 'one' && isFeminine) suffix = 're';
  return withNr ? `${nr}${suffix}` : suffix;
};

export const getHoursMinsSecondsFromMs = (ms) => {
  const [hours, minutes, seconds] = new Date(ms).toISOString().substr(11, 8).split(':');
  return { hours, minutes, seconds };
};

export const truncate = (str, n) => {
  return str.length > n ? `${str.slice(0, n - 1)}…` : str;
};

// src: https://www.30secondsofcode.org/js/s/throttle-function/
/* eslint-disable */
export const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime;
  return function () {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(
        function () {
          if (Date.now() - lastTime >= wait) {
            fn.apply(context, args);
            lastTime = Date.now();
          }
        },
        Math.max(wait - (Date.now() - lastTime), 0)
      );
    }
  };
};

export const getLetterByIdx = (idx) => ['A', 'B', 'C', 'D'][idx];

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
