import { makeAutoObservable, observable, values, when } from 'mobx';
import { now } from 'mobx-utils';
// import LogRocket from 'logrocket';
import { startRouter } from '../lib/router';
import SyncStore from './SyncStore';
import ViewStore from './ViewStore';
import TeamStore from './TeamStore';
import ChallengeStore from './ChallengeStore';
import MapStore from './MapStore';
import I18nStore from './I18nStore';
import NotificationStore from './NotificationStore';
import { getTeamById } from '../lib/api';
import { MATCH_STATES, MATCH_SETTINGS } from '../lib/constants';
import { getHoursMinsSecondsFromMs } from '../lib/utils';

const { SETUP, STARTED, COUNTDOWN, GAME_OVER } = MATCH_STATES;
const { DURATION_IN_MS, COUNTDOWN_IN_MS } = MATCH_SETTINGS;

class RootStore {
  urlId;

  game;

  state = SETUP;

  currentTeamId;

  showScoreboard = false;

  teams = new Map();

  challenges = new Map();

  notifications = new Map();

  map;

  startedAt;

  countdownStartedAt;

  constructor(urlId) {
    makeAutoObservable(this, {
      urlId: false,
      game: observable.ref,
      teams: observable.shallow,
      challenges: observable.shallow,
      map: observable.shallow,
      notifications: observable.shallow,
    });

    this.urlId = urlId;
    this.view = new ViewStore(this);
    this.sync = new SyncStore(this);
    this.map = new MapStore(this);
    this.i18n = new I18nStore();

    this.setup();

    when(
      () => this.isReady,
      () => {
        startRouter(this);
        document.title = `${this.game.name} | Urbant Hunt`;
      }
    );
  }

  // this checks whether we have a valid session id and fetches all game data
  async setup() {
    if (!this.urlId || this.urlId.length !== 5) {
      this.view.setScreen('error');
      return;
    }
    const team = await getTeamById(this.urlId);

    if (team) {
      this.setCurrentTeam(team);
      this.i18n.initLocale(team.locale);
      this.sync.connect();
      // if (import.meta.env.PROD) LogRocket.init('rgesqi/homepage');
    } else {
      this.view.setScreen('error');
    }
  }

  // this means: show all correct solutions once the game is over
  get inReviewMode() {
    return this.state >= GAME_OVER;
  }

  get currentTeam() {
    return this.teams.get(this.currentTeamId);
  }

  get teamCount() {
    return this.teams.size;
  }

  // nr of teams that have the isReady flag enabled
  get readyTeamCount() {
    return values(this.teams).filter((team) => team.isReady).length;
  }

  get unreadyTeamCount() {
    return this.teamCount - this.readyTeamCount;
  }

  get isReady() {
    return this.sync.isReady && this.i18n.isReady;
  }

  get demoChallenge() {
    return values(this.challenges).find((challenge) => challenge.isDemo);
  }

  get solvedDemoChallenge() {
    return this.demoChallenge.hasBeenAnswered;
  }

  get teamsByRankAsArray() {
    return values(this.teams).sort(
      (a, b) =>
        // eslint-disable-next-line no-nested-ternary
        (a.isReady === b.isReady ? 0 : a.isReady ? 1 : -1) ||
        b.score - a.score ||
        b.correctAnswerCount - a.correctAnswerCount ||
        b.scoreWithoutRewards - a.scoreWithoutRewards ||
        a.name.localeCompare(b.name, this.i18n.locale, { sensitivity: 'base' })
    );
  }

  get notificationsByCreatedAtDescAsArray() {
    return values(this.notifications).sort((a, b) => b.createdAt - a.createdAt);
  }

  get timeLeftInMs() {
    // todo duration should best come from the server
    if (this.state < STARTED) return DURATION_IN_MS;
    if (this.state === STARTED) {
      const timeLeftInMs = DURATION_IN_MS - (now() - this.startedAt);
      return timeLeftInMs > 0 ? timeLeftInMs : 0;
    }
    return 0;
  }

  get timeLeftFormatted() {
    return getHoursMinsSecondsFromMs(this.timeLeftInMs);
  }

  get countdownTimeLeftInMs() {
    if (this.state < COUNTDOWN) return COUNTDOWN_IN_MS;
    if (this.state === COUNTDOWN) {
      const timeLeftInMs = COUNTDOWN_IN_MS - (now() - this.countdownStartedAt);
      return timeLeftInMs > 0 ? timeLeftInMs : 0;
    }
    return 0;
  }

  setCurrentTeam(team) {
    this.currentTeamId = team.id;
  }

  setTeams(teams) {
    teams.forEach((team) => {
      if (this.teams.has(team.id)) {
        // update existing
        this.teams.get(team.id).updateFromJson(team);
        return;
      }
      // no existing found, add a new one
      const newTeam = new TeamStore(this, team.id);
      newTeam.updateFromJson(team);
      this.teams.set(team.id, newTeam);
    });
  }

  setChallenges(challenges) {
    challenges.forEach((challenge) => {
      if (this.challenges.has(challenge.id)) {
        // update existing
        this.challenges.get(challenge.id).updateFromJson(challenge);
        return;
      }
      // no existing found, add a new one
      const newChallenge = new ChallengeStore(this, challenge.id);
      newChallenge.updateFromJson(challenge);
      this.challenges.set(challenge.id, newChallenge);
    });
  }

  addNotification(notificationObj) {
    const notification = new NotificationStore(this, notificationObj);
    this.notifications.set(notification.id, notification);
    return notification;
  }

  setNotifications(notifications) {
    notifications.forEach((notification) => {
      this.addNotification(notification);
    });
  }

  updateFromJson(json) {
    Object.keys(json).forEach((key) => {
      this[key] = json[key];
    });
  }
}

export default RootStore;
