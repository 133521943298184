import { observer } from 'mobx-react-lite';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { Transition, Button, TransitionChild } from '@headlessui/react';
import { useStore } from './StoreProvider';

const Panel = observer(function Panel({ isOpen = false, title, subtitle, rightHeader, children }) {
  const store = useStore();

  const close = async () => {
    store.view.closePanel();
  };

  return (
    <Transition
      show={isOpen}
      className="fixed inset-0 z-20 overflow-hidden"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-y-0 right-0 flex max-w-full">
          <TransitionChild
            enter="transition ease-in-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            className="w-screen"
          >
            <div className="relative flex h-full flex-col bg-gray-900 shadow-xl">
              <header className="absolute top-0 z-10 w-full border-b border-gray-700 bg-gray-800 px-2 py-1.5">
                <div className="flex h-9 items-center overflow-visible">
                  <div className="flex flex-1 items-center">
                    <div className="-ml-2 flex items-center justify-center">
                      <Button onClick={() => close()} className="flex items-center text-indigo-400">
                        <ChevronLeftIcon className="size-8" />
                        <div className="-ml-1.5 text-sm font-light">
                          {store.i18n.rosetta.t('panel.map')}
                        </div>
                      </Button>
                    </div>
                  </div>
                  <div className="flex-1 text-center leading-tight">
                    {title && <h2 className="font-semibold text-gray-100">{title}</h2>}
                    {subtitle && <div className="text-xs font-light text-gray-400">{subtitle}</div>}
                  </div>
                  <div className="flex flex-1 justify-end">{rightHeader}</div>
                </div>
              </header>
              <main className="mt-[3.125rem] overflow-y-scroll px-3 pb-[3.875rem] pt-4">
                {children}
              </main>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Transition>
  );
});

export default Panel;
