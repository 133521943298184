import { makeObservable, computed } from 'mobx';
import { now } from 'mobx-utils';
import { timeSince } from '../lib/utils';

// todo: implement a status flag, eg:
// Status 0, means that the notification is shown, and not read.
// Status 1, means that the notification is hidden, and not read.
// Status 2, means that the notification is shown, and it has been read.
// Status 3, means that the notification is hidden, and it has been read.

class NotificationStore {
  id;

  type;

  metadata = {};

  constructor(rootStore, { id, type, metadata, createdAt }) {
    makeObservable(this, { timeAgo: computed });

    this.id = id;
    this.rootStore = rootStore;
    this.type = type;
    this.metadata = metadata;
    this.createdAt = createdAt;
  }

  get timeAgo() {
    const { value, unit } = timeSince(now(60 * 1000) - this.createdAt);
    if (unit === 'seconds') return this.rootStore.i18n.rosetta.t('feed.justNow');
    if (unit === 'minutes')
      return this.rootStore.i18n.rosetta.t('feed.timeInMinutes', { minutes: value });
    return this.rootStore.i18n.rosetta.t('feed.timeInHours', { hours: value });
  }
}

export default NotificationStore;
