import { Dialog, DialogPanel, DialogBackdrop, Transition } from '@headlessui/react';
import { observer } from 'mobx-react-lite';

const CountdownOverlay = observer(function CountdownOverlay({ store }) {
  return (
    <Transition appear show={store.view.showCountdownOverlay}>
      <Dialog as="div" className="relative z-40 focus:outline-none" onClose={() => {}}>
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/90 backdrop-blur-lg duration-300 ease-out data-[closed]:opacity-0"
        />
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center">
            <DialogPanel
              transition
              className="w-full max-w-xs rounded-xl p-2 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
            >
              <div className="flex flex-col items-center">
                <div className="animate-bounce text-[10rem] font-black leading-none text-gray-100">
                  {Math.round(store.countdownTimeLeftInMs / 1000)}
                </div>
                <div className="rounded-xl bg-black/50 px-4 py-3 text-sm text-gray-300 backdrop-blur-xl">
                  {store.i18n.rosetta.t('countdown.description', {
                    seconds: Math.round(store.countdownTimeLeftInMs / 1000),
                  })}
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});

export default CountdownOverlay;
