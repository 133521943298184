import { observer } from 'mobx-react-lite';
import Map from './Map';
import Loading from './Loading';
import MiniScoreboard from './MiniScoreboard';
import CountdownClock from './CountdownClock';
import ReadyBanner from './ReadyBanner';
import CountdownOverlay from './CountdownOverlay';
import GeolocationModal from './GeolocationModal';
import HudOverlay from './HudOverlay';
import PanelWithActiveScreen from './PanelWithActiveScreen';
import NotificationBanner from './NotificationBanner';
import ChatwootWidget from './ChatwootWidget';
import Error from './Error';
import { useStore } from './StoreProvider';

const Play = observer(function Play() {
  const store = useStore();

  if (store.view.screen === 'error') return <Error>Invalid link</Error>;

  if (!store.isReady) return <Loading />;

  return (
    <div>
      <PanelWithActiveScreen store={store} />
      <NotificationBanner store={store} />
      <CountdownOverlay store={store} />
      <HudOverlay>
        <CountdownClock store={store} />
        {store.showScoreboard && <MiniScoreboard store={store} />}
      </HudOverlay>
      <GeolocationModal store={store} />
      {import.meta.env.PROD && <ChatwootWidget store={store} />}
      <Map store={store} />
      <ReadyBanner store={store} />
    </div>
  );
});

export default Play;
