import { observer } from 'mobx-react-lite';
import PortableText from './PortableText';

const ExplanationBlock = observer(function ExplanationBlock({ store }) {
  const challenge = store.view.activeChallenge;

  if (!challenge) return null;

  return (
    <>
      <h3 className="mt-6 text-xl font-semibold leading-loose text-gray-100">
        {store.i18n.rosetta.t('challenge.explanation')}
      </h3>
      <div className="prose whitespace-pre-line text-gray-200 prose-strong:text-white">
        <PortableText value={challenge.explanation} />
      </div>
    </>
  );
});

export default ExplanationBlock;
