import { Transition, Button } from '@headlessui/react';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import PortableText from './PortableText';
import ExplanationBlock from './ExplanationBlock';
import ChallengeFeedbackOverlay from './ChallengeFeedbackOverlay';
// import clsx from 'clsx';
import { LoaderIcon } from './Icons';
import { TextAnswers, ImageAnswers, VideoAnswers } from './AnswerBlocks';
import FeedbackWidget from './FeedbackWidget';

const renderAnswersList = (store) => {
  const challenge = store.view.activeChallenge;

  if (challenge.answerType === 'textAnswer') return <TextAnswers store={store} />;
  if (challenge.answerType === 'imageAnswer') return <ImageAnswers store={store} />;
  if (challenge.answerType === 'videoAnswer') return <VideoAnswers store={store} />;

  return null;
};

const Footer = observer(function Footer({ challenge, t }) {
  return (
    <Transition
      show={!challenge.hasBeenAnswered && challenge.selectedAnswerIdx !== null}
      enter="transition ease-in-out duration-300"
      enterFrom="translate-y-full"
      enterTo="translate-y-0"
      leave="transition ease-in-out duration-300"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-full"
      className="absolute bottom-0 left-0 w-full"
    >
      <footer className="flex space-x-3 border-t border-gray-700 bg-gray-800 px-4 pb-6 pt-2.5">
        <Button
          className="w-full whitespace-nowrap rounded-md border border-transparent bg-gray-700 px-4 py-2 text-center text-sm font-medium text-white"
          onClick={() => challenge.deselectAnswer()}
        >
          {t('challenge.cancel')}
        </Button>
        <Button
          disabled={challenge.isLoading}
          onClick={() => challenge.solve()}
          // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
          className={clsx(
            'inline-flex w-full items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white',
            { 'cursor-not-allowed bg-opacity-40': challenge.isLoading }
          )}
        >
          {challenge.isLoading ? (
            <LoaderIcon className="size-5" />
          ) : (
            <span>{t('challenge.confirm')} &rarr;</span>
          )}
        </Button>
      </footer>
    </Transition>
  );
});

const Challenge = observer(function Challenge({ store }) {
  const challenge = store.view.activeChallenge;

  return (
    <>
      <ChallengeFeedbackOverlay store={store} />
      <div className="relative mx-auto max-w-prose pb-12">
        {challenge.trivia && (
          <div className="relative isolate overflow-hidden rounded-lg border-2 border-indigo-600/25 bg-indigo-800 p-4 text-left">
            <h3 className="mt-6 inline-flex items-center text-xl font-semibold text-indigo-100 first:mt-0">
              <ChatBubbleBottomCenterTextIcon className="mr-2 size-6 text-indigo-300" />
              {store.i18n.rosetta.t('challenge.trivia')}
            </h3>
            <div className="prose mt-1 whitespace-pre-line leading-normal text-indigo-200 prose-strong:text-white">
              <PortableText value={challenge.trivia} />
            </div>
            <ChatBubbleBottomCenterTextIcon className="absolute right-0 top-0 -z-10 mr-1 size-32 text-indigo-500/25" />
          </div>
        )}
        {challenge.description && (
          <>
            <h3 className="mt-6 text-xl font-semibold leading-loose text-gray-100 first:mt-0">
              {store.i18n.rosetta.t('challenge.description')}
            </h3>
            <div className="prose whitespace-pre-line text-gray-200 prose-strong:text-white">
              <PortableText value={challenge.description} />
            </div>
          </>
        )}
        <h3 className="mt-6 text-xl font-semibold leading-loose text-gray-100 first:mt-0">
          {store.i18n.rosetta.t('challenge.question')}
        </h3>
        <div className="prose whitespace-pre-line text-gray-200 prose-strong:text-white">
          <PortableText value={challenge.question} />
        </div>
        <h3 className="mt-6 text-xl font-semibold leading-loose text-gray-100">
          {store.i18n.rosetta.t('challenge.answers')}
        </h3>
        <div className="mt-2">{renderAnswersList(store)}</div>
        <div id="explanation">
          {challenge.canBeSpoiled && challenge.explanation && <ExplanationBlock store={store} />}
        </div>
        <div className="mt-5">
          <FeedbackWidget challengeId={challenge.cmsId} store={store} />
        </div>
      </div>
      <Footer challenge={challenge} t={store.i18n.rosetta.t} />
    </>
  );
});

export default Challenge;
