import { reaction, makeObservable, observable, action } from 'mobx';
import mapboxgl from 'mapbox-gl';
import { DEFAULT_MAP_ZOOM } from '../lib/constants';

class MapStore {
  markers = new Map();

  paintDisposer;

  paintCallback;

  isCentered = true;

  constructor(rootStore) {
    makeObservable(this, {
      isCentered: observable,
      setIsCentered: action,
    });

    this.rootStore = rootStore;
  }

  get defaultLatitude() {
    return parseFloat(this.rootStore.game.latitude);
  }

  get defaultLongitude() {
    return parseFloat(this.rootStore.game.longitude);
  }

  get defaultCenter() {
    return [this.defaultLongitude, this.defaultLatitude];
  }

  get maxBounds() {
    return [
      [this.defaultLongitude - 0.02, this.defaultLatitude - 0.02], // [west, south]
      [this.defaultLongitude + 0.02, this.defaultLatitude + 0.02], // [east, north]
    ];
  }

  center() {
    this.flyTo([this.rootStore.game.longitude, this.rootStore.game.latitude], DEFAULT_MAP_ZOOM);
  }

  setIsCentered(isCentered) {
    this.isCentered = isCentered;
  }

  flyTo(center, zoom) {
    if (!this.mapbox) return;
    this.mapbox.flyTo({ center, zoom, speed: 2 });
  }

  flyToChallenge(challenge) {
    this.flyTo([challenge.longitude, challenge.latitude], 18);
  }

  linkMapbox(mapbox) {
    this.mapbox = mapbox;
    this.setupMoveListener();
  }

  unlinkMapbox() {
    if (!this.mapbox) return;

    this.dispose();
    this.mapbox = null;
  }

  paintMarkers(paintCallback) {
    this.paintCallback = paintCallback;

    this.paintDisposer = reaction(
      () => this.rootStore.challenges.size,
      () => {
        this.rootStore.challenges.forEach((challenge) => {
          const marker = this.createMarker(challenge);
          paintCallback(challenge, marker.getElement());
        });
      },
      { fireImmediately: true }
    );
  }

  createMarker(challenge) {
    if (this.markers.has(challenge.id)) {
      console.log('Already on the map');
      return this.markers.get(challenge.id);
    }

    const htmlEl = document.createElement('div');
    htmlEl.addEventListener('click', (e) => {
      this.rootStore.view.openChallengeInPanel(challenge);
      e.stopPropagation();
    });
    // see: https://docs.mapbox.com/mapbox-gl-js/api/markers/#marker
    const marker = new mapboxgl.Marker(htmlEl).setLngLat(challenge.coordinates).addTo(this.mapbox);
    this.markers.set(challenge.id, marker);
    return marker;
  }

  paintClientMarkersForTeam(team) {
    if (!this.mapbox) return;

    if (!this.mapbox.getLayer(team.id)) {
      // FIXME this sometimes causes:  Error · Style is not done loading
      this.mapbox.addSource(team.id, {
        type: 'geojson',
        data: team.allClientsAsGeoJson,
      });
      this.mapbox.addLayer({
        id: team.id,
        type: 'symbol',
        source: team.id,
        layout: {
          'icon-image': 'person-walking',
          'icon-size': 0.2,
          'text-field': '{title}',
          'text-size': 11,
          'icon-ignore-placement': true,
          'icon-allow-overlap': true,
          'text-ignore-placement': true,
          'text-allow-overlap': true,
          'text-offset': [0, 1.1],
          'text-anchor': 'top',
        },
        paint: {
          'text-color': '#000',
          'text-halo-color': '#FFF',
          'text-halo-width': 1,
        },
        filter: ['==', '$type', 'Point'],
      });
    } else {
      this.mapbox.getSource(team.id).setData(team.allClientsAsGeoJson);
    }
  }

  setupMoveListener() {
    if (!this.mapbox) return;

    this.mapbox.on('moveend', () => {
      const zoom = this.mapbox.getZoom().toFixed(2);
      const lat = this.mapbox.getCenter().lat.toFixed(4);
      const lng = this.mapbox.getCenter().lng.toFixed(4);

      const hasMoved =
        zoom !== DEFAULT_MAP_ZOOM.toFixed(2) ||
        this.defaultLongitude.toFixed(4) !== lng ||
        this.defaultLatitude.toFixed(4) !== lat;

      this.setIsCentered(!hasMoved);
    });
  }

  dispose() {
    if (this.paintDisposer) this.paintDisposer();
    this.markers.clear();
    this.paintCallback = null;
    this.mapbox.remove();
  }
}

export default MapStore;
