import { LoaderIcon } from './Icons';

const Loading = () => {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-around overflow-hidden text-white">
      <div className="flex flex-col items-center">
        <LoaderIcon className="size-8" />
        <div className="mt-2 text-sm text-gray-200">Loading Game</div>
      </div>
    </div>
  );
};

export default Loading;
