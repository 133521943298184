import { observer } from 'mobx-react-lite';

const HudOverlay = observer(function HudOverlay({ children }) {
  return (
    <div className="fixed left-0 z-10 m-2 w-32 select-none overflow-hidden rounded-lg bg-gray-900/90 ring-4 ring-gray-900/20">
      {children}
    </div>
  );
});

export default HudOverlay;
