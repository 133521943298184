import { useRef, useEffect } from 'react';

export function useScrollBack(delay = 5000) {
  const scrollRef = useRef(null);

  useEffect(() => {
    let timeoutId;
    const handleScroll = () => {
      clearTimeout(timeoutId);
      const elem = scrollRef.current;
      if (elem?.scrollLeft <= 0) return;
      timeoutId = setTimeout(() => {
        if (elem) elem.scrollTo({ left: 0, behavior: 'smooth' });
      }, delay);
    };
    const elem = scrollRef.current;
    if (elem) elem.addEventListener('scroll', handleScroll);
    return () => {
      if (elem) elem.removeEventListener('scroll', handleScroll);
    };
  }, [delay]);

  return { scrollRef };
}
